<!-- IS Header -->
<ui-external-header
  currentSiteName="Startup Hub"
  [adminRouterLink]="(auth.perm('admin-panel')) ? '/admin' : ''"
></ui-external-header>

<!-- Header -->
<ui-header
  #header
  logo="assets/img/logo.svg"
  [items]="menuItems"
  [showMobileMenu]="true"
  [isLogged]="auth.isLogged"
  [userName]="auth.user.name || ''"
  [userAvatar]="avatar"
  (onLogin)="onLogin()"
  (onRegister)="onRegister()"
></ui-header>
<!--<st-header></st-header>-->

<div class="content">
  <router-outlet/>
</div>

<!-- Footer -->
@if (isFooter) {
  <st-footer></st-footer>
}

<!-- Modals -->
@for (m of modals; track $index) {
  <st-modal [data]="m" [style.z-index]="100 + $index"></st-modal>
}

